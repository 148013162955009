import * as React from 'react'
import * as css from './events.module.css'
import DefaultLayout from 'layouts/Default'

const events = () => (
  <DefaultLayout>
    <section className="container padding richtext">
      <h1 className="h1">Events</h1>

      <iframe
        className={css.iframe}
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&src=ZXZlbnRzLmNwc2luc3VyYW5jZUBnbWFpbC5jb20&color=%23039BE5&showTitle=0&showNav=1&showTz=1&showCalendars=0&showTabs=1&showPrint=0&showDate=1"
        height="600"
        frameBorder="0"
        scrolling="no"
        title="CPS Insurance Events"
      ></iframe>
    </section>
  </DefaultLayout>
)

export default events
